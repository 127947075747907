<template>
  <div class="page">
    <div
      v-if="routeFrom && (routeFrom.name === 'Accounts')"
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('words.accountsManagement') }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Spheres' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('addSphere.spheresList') }}
      </span>
    </div>

    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <b-container fluid>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
          >
            <h2>
              {{ $t('addSphere.title') }}
            </h2>
            <form>
              <!-- NAME -->
              <div
                v-if="isFieldVisible('name', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-12">
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    :rules="isFieldRequired('name', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <label :class="isFieldRequired('name', formConfig.requiredFields)">
                        {{ $t('addSphere.form.name.label') }}
                      </label>
                      <p class="label-help">
                        {{ $t('addSphere.form.name.help') }}
                      </p>
                      <input
                        v-model="name"
                        v-sanitize
                        class="form-control"
                        type="text"
                        :placeholder="$t('addSphere.form.name.label')"
                      >
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <!-- DESCRIPTION -->
              <div
                v-if="isFieldVisible('name', formConfig.hiddenFields)"
                class="form-row"
              >
                <div class="form-group col-12">
                  <label :class="isFieldRequired('name', formConfig.requiredFields)">
                    {{ $t('addSphere.form.description') }}
                  </label>
                  <ValidationProvider
                    v-slot="{ classes, errors }"
                    :rules="isFieldRequired('description', formConfig.requiredFields)"
                  >
                    <div
                      class="control"
                      :class="classes"
                    >
                      <textarea
                        v-model="description"
                        v-sanitize
                        class="form-control"
                      />
                      <span class="form-errors">{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <SphereCard
              :sphere="{ display_name: name}"
            />
            <div class="side-footer">
              <b-button
                variant="primary"
                @click="handleSubmit(addSphere)"
              >
                {{ $t('buttons.save') }}
              </b-button>
              <b-button
                variant="primary"
                @click="handleSubmit(addSphereAndContinue)"
              >
                {{ $t('buttons.saveContinue') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import SphereCard from '@/components/Cards/SphereCard.vue';

import { ValidationObserver, ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import { slugify } from '@/utils';


extend('required', {
  ...required,
  message: 'Ce champ est requis'
});

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'AddSphere',

  components: {
    SphereCard,
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      loading: false,
      routeFrom: null,
      // form
      name: null,
      description: null
    };
  },

  computed: {
    ...mapState('usergroups', [
      'lastCreatedUsergroup'
    ]),

    formConfig() {
      return this.$config.forms.user;
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },

  methods: {
    ...mapMutations('usergroups', [
      'SET_LAST_CREATED_USERGROUP'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUP_DETAIL',
      'GET_USERGROUPS_SPHERES_LIST',
      'CREATE_USERGROUP'
    ]),

    addSphere() {
      this.loading = true;
      const data = {
        display_name: this.name,
        codename: slugify(this.name),
        description: this.description,
        usergroup_type: {
          codename: 'group-of-organisation'
        },
        is_active: true
      };
      this.CREATE_USERGROUP({
        data: data,
        usergroupType: 'sphere'
      })
        .then(() => {
          this.GET_USERGROUPS_SPHERES_LIST({
            direction: null,
            field: null
          }).then(() => {
            this.loading = false;
            this.$router.push({ name: 'Spheres' });
          });
        }).catch(() => {
          this.loading = false;
        });
    },
    addSphereAndContinue() {
      this.loading = true;
      const data = {
        display_name: this.name,
        codename: slugify(this.name),
        description: this.description,
        usergroup_type: {
          codename: 'group-of-organisation'
        },
        is_active: true
      };
      this.CREATE_USERGROUP({
        data: data,
        usergroupType: 'sphere'
      })
        .then(() => {
          if (this.lastCreatedUsergroup) {
            Promise.all([
              this.GET_USERGROUP_DETAIL(this.lastCreatedUsergroup.id),
              this.GET_USERGROUPS_SPHERES_LIST({
                direction: null,
                field: null
              })
            ]).then(() => {
              this.loading = false;
              this.$router.push({ name: 'SphereDetail', params: { id: this.lastCreatedUsergroup.id } });
              this.SET_LAST_CREATED_USERGROUP(null);
            });
          } else {
            this.loading = false;
          }
        }).catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.add-sphere-form {
  margin-left: max(2em, auto);
  margin-right: max(2em, auto);
}

form {
  max-width: 1000px;
}

h2 {
  color: @blue;
  margin-top: 0.5em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}
</style>
